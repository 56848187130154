import React, { useState, useEffect, createContext, useContext } from 'react'
import Api from 'utils/api'
import { useParams } from 'react-router-dom'

const initBrandContextPropsState = {
  customers: [],
  selectedPage: undefined,
  setselectedPage: () => {},
  setCustomers: () => {},
  setPreviewState: () => {},
  getPreviewState: () => {},
}

const PageMapContext = createContext(initBrandContextPropsState)

const usePageMap = () => {
  return useContext(PageMapContext)
}

const PageMapProvider = ({ children }) => {
  const [loader, setLoader] = useState(false)
  const [selectedPage, setselectedPage] = useState()
  const [customers, setCustomers] = useState([])
  const { page_id } = useParams()

  const getCustomers = async () => {
    const { response } = await Api(
      `/directory/getnewdirectory?startAfter=0&id=${page_id}`,
      'get'
    )
    if (response) {
      setselectedPage(response?.data?.page)
      setCustomers(response.data?.contacts || [])
    }
    console.log(response?.data.page, 'response page')
    setLoader(false)
  }

  const onLoad = () => {
    getCustomers()
  }

  useEffect(onLoad, [])

  const setPreviewState = (values) => {
    localStorage.setItem('previewState', JSON.stringify(values))
  }

  const getPreviewState = () => {
    const previewState = JSON.parse(localStorage.getItem('previewState'))
    return previewState
  }

  return (
    <PageMapContext.Provider
      value={{
        selectedPage,
        setselectedPage,
        setPreviewState,
        getPreviewState,
      }}
    >
      {children}
    </PageMapContext.Provider>
  )
}

export { PageMapProvider, usePageMap }
