import logo from './logo.svg'
import './App.css'
import { useRoutes } from 'react-router-dom'
import Routes from 'routing/routes'

function App() {
  const routes = useRoutes(Routes())

  return <div>{routes}</div>
}

export default App
