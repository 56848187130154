import React from 'react'
import AppRoutes from './app-routes'

const Routes = () => {
  const currentRoutes = AppRoutes()

  return currentRoutes
}

export default Routes
