import { PageMapProvider, usePageMap } from 'context/pagebranding'
import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { Outlet } from 'react-router-dom'

function PagesModule() {
  const { getPreviewState } = usePageMap()

  return (
    <PageMapProvider>
      <HelmetProvider>
        {' '}
        <Outlet />{' '}
      </HelmetProvider>
    </PageMapProvider>
  )
}

export default PagesModule
