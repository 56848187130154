import { lazy } from 'react'
import ComponentLoader from './component-loader'
import { Navigate } from 'react-router-dom'
import AppLayout from 'pages/AppLayout'

const Classic = ComponentLoader(lazy(() => import('pages/Classic/home')))
const Modern = ComponentLoader(lazy(() => import('pages/Modern/home')))
// const BusinessDetails = ComponentLoader(
//   lazy(() => import('pages/business-details'))
// )

const AppRoutes = () => {
  const routes = [
    {
      path: 'page',
      element: <AppLayout />,
      children: [
        {
          path: 'classic',
          children: [
            {
              path: ':page_id',
              element: <Classic />,
            },
            // {
            //   path: 'customer-details',
            //   element: <BusinessDetails />,
            // },
          ],
        },
        {
          path: 'modern',
          children: [
            {
              path: ':page_id',
              element: <Modern />,
            },
            // {
            //   path: 'customer-details',
            //   element: <BusinessDetails />,
            // },
          ],
        },
      ],
    },
    {
      path: '',
      index: true,
      element: <Navigate replace to={'/page'} />,
    },
    {
      path: '*',
      index: true,
      element: <Navigate replace to={'/page'} />,
    },
  ]
  return routes
}

export default AppRoutes
